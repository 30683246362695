import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/storage';
import { axiosInstance } from '../../../utils/axios';
import useModalStore from '../../../stores/useModalStore';

import {
  CheckIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
  PencilIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
  QrCodeIcon,
} from '@heroicons/react/20/solid';
import Sidebar from '../../../components/AdminSidebar';
import AdminSidebar from '../../../components/AdminSidebar';
import Pagination from '../../../components/Pagination';
import QRCodeScannerModal from '../../../components/QRCodeScannerModal';
import { CSVLink, CSVDownload } from 'react-csv';
import QReaderDeviceList from '../../../components/QReaderDeviceList';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function DeviceList() {
  const [devices, setDevices] = useState([]);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
  const { showLoading, setShowLoading, showModal, setShowModal, serialNo, setSerialNo } = useModalStore();
 

  const [customCSVData, setCustomCSVData] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({
    brand: '',
    model: '',
    serial_no: '',
    counter_black: '',
    counter_color:'',
    total_counter:'',
    date_registered: '',
  });

  const [showSearch, setShowSearch] = useState({
    brand: false,
    model: false,
    serial_no: false,
    counter_black:false,
    counter_color:false,
    total_counter:false,
    date_registered: false,
  });

  const user = getUser();
  const navigate = useNavigate();
  const [isQRCodeScannerOpen, setQRCodeScannerOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  

  useEffect(() => {
    fetchDevices();
   
  }, []);

  useEffect(() => {
    if(serialNo != null){   
      alert("Scanned and filtered.");   
      setSearchCriteria({
        brand: '',
        model: '',
        serial_no: serialNo,
        counter_black: '',
        counter_color:'',
        total_counter:'',
        date_registered: '',
      }); 

      setSerialNo(null);
      
      // closeQRCodeScanner();
      
    }

    if(serialNo == null && searchCriteria.serial_no != "") {
     
      const fetchData = async () => {    
        try {
          const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/search-devices', {
            params: searchCriteria, // Pass the search criteria as query parameters
          });
  
          console.log('devices search', response.data);
          setDevices(response.data.data.data);

          // setError(null);
        } catch (error) {
          setError('Failed to fetch devices. Please try again.');
          console.error('Fetch devices error:', error);
        }
        // alert("Scanned");
        
      }
      fetchData();
     
      
     
      
      
      // closeQRCodeScanner();
    }
  }, [serialNo, searchCriteria.serial_no]);

  const fetchDevices = async () => {
    try {
      const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/devices');
      console.log('devices', response.data);
      
      // Separate devices with blue options from others
      const devicesWithBlueOptions = response.data.data.filter(device => 
        device.device_options.blue === 1
      );
      const otherDevices = response.data.data.filter(device => 
        device.device_options.blue !== 1
      );

      // Concatenate devices with blue options first
      const sortedDevices = [...devicesWithBlueOptions, ...otherDevices];

      // Set the sorted devices state
      setDevices(sortedDevices);

      setError(null);
    } catch (error) {
      setError('Failed to fetch devices. Please try again.');
      console.error('Fetch devices error:', error);
    }
  };


  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`${window.REACT_APP_API_ENDPOINT}/api/devices/${id}`);
      setDevices(devices.filter((device) => device.id !== id));
      setShowDeleteModal(false);
      setError(null);
    } catch (error) {
      setError('Failed to delete device. Please try again.');
      console.error('Delete device error:', error);
    }
  };

  const openDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeviceIdToDelete(id);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeviceIdToDelete(null);
  };

  const handleRadioChange = async (optionId) => {
    try {
      // Make an API call to update the device option
      const response = await axiosInstance.put(
        `${window.REACT_APP_API_ENDPOINT}/api/device-options/${optionId}`,
        { blue: 1 }
      );

      // Update the selected option state
      // setSelectedOption(optionId);

      // Handle success or show notification
      console.log('Device option updated:', response.data);
      fetchDevices();
    } catch (error) {
      // Handle error or show error notification
      console.error('Error updating device option:', error);
    }
  };

  // per search
  const searchDevices = async (e) => {
    const searchCriteriaValues = Object.values(searchCriteria);
    if(searchCriteriaValues.every(value => value === '')){
      fetchDevices()
    }
    else if (e.key === 'Enter' && searchCriteria !== '') {
      try {
        const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/search-devices', {
          params: searchCriteria, // Pass the search criteria as query parameters
        });

        console.log('devices search', response.data);
        setDevices(response.data.data.data);
        // setError(null);
      } catch (error) {
        setError('Failed to fetch devices. Please try again.');
        console.error('Fetch devices error:', error);
      }
    }
};

  const searchAllDevices = async (e) => {
    if (e.key === 'Enter') {
      const searchText = e.target.value.trim().toLowerCase();
      if (searchText === '') {
        // If search text is empty, fetch all devices
        fetchDevices();
      } else {
        try {
          // Filter devices based on search criteria
          const filteredDevices = devices.filter(device => 
            device.brand.toLowerCase().includes(searchText) ||
            device.model.toLowerCase().includes(searchText) ||
            device.serial_no.toLowerCase().includes(searchText)
          );
          setDevices(filteredDevices);
          setError(null);          
        } catch (error) {
          setError('Failed to search devices. Please try again.');
          console.error('Search devices error:', error);
        }
      }
    }
  };


  const toggleSearch = (optionName) => {
    setSearchCriteria({
      brand: '',
      model: '',
      serial_no: '',
      counter_black: '',
      counter_color:'',
      total_counter:'',
      date_registered: '',
    })
    setShowSearch((prevState) => ({
      ...prevState,
      [optionName]: !prevState[optionName],
    }));
  };

  const sortByBrand = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.brand.localeCompare(b.brand);
      } else {
        return b.brand.localeCompare(a.brand);
      }
    });
    setDevices(sortedDevices);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const filterByModel = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      // Split model strings into parts: text and numbers
      const [textA, numberA] = extractModelParts(a.model);
      const [textB, numberB] = extractModelParts(b.model);

      // Compare text parts alphabetically
      const textComparison = textA.localeCompare(textB);

      // If text parts are equal, compare number parts numerically
      if (textComparison === 0) {
        // Handle ascending and descending order
        return sortOrder === 'asc' ? numberA - numberB : numberB - numberA;
      } else {
        // If text parts are not equal, return based on sortOrder
        return sortOrder === 'asc' ? textComparison : textComparison * -1;
      }
    });
    setDevices(sortedDevices);
    // Toggle sortOrder between 'asc' and 'desc'
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  const extractModelParts = (model) => {
    // Split model into text and number parts
    const regex = /([a-zA-Z]+)(\d+)/;
    const matches = model.match(regex);
    if (matches && matches.length === 3) {
      return [matches[1], parseInt(matches[2], 10)];
    }
    // If no match, return original string with a high number
    return [model, Infinity];
  };

  const filterBySerialNumber = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      const [textA, numberA] = extractSerialParts(a.serial_no);
      const [textB, numberB] = extractSerialParts(b.serial_no);
      const textComparison = textA.localeCompare(textB);
      if (textComparison === 0) {
        // Handle ascending and descending order
        return sortOrder === 'asc' ? numberA - numberB : numberB - numberA;
      } else {
        // If text parts are not equal, return based on sortOrder
        return sortOrder === 'asc' ? textComparison : textComparison * -1;
      }
    });
    setDevices(sortedDevices);
    // Toggle sortOrder between 'asc' and 'desc'
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const extractSerialParts = (serial) => {
    if (!serial) {
      return ['', Infinity];
    }
    
    const regex = /([a-zA-Z]+)(\d+)/;
    const matches = serial.match(regex);
    
    if (matches && matches.length === 3) {
      return [matches[1], parseInt(matches[2], 10)];
    }
    return [serial, Infinity];
  };

  const sortByCounterBlack = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.counter_black - b.counter_black;
      } else {
        return b.counter_black - a.counter_black;
      }
    });
    setDevices(sortedDevices);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortByCounterColor = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.counter_color - b.counter_color;
      } else {
        return b.counter_color - a.counter_color;
      }
    });
    setDevices(sortedDevices);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortByTotalColor = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.total_counter - b.total_counter;
      } else {
        return b.total_counter - a.total_counter;
      }
    });
    setDevices(sortedDevices);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortByDateRegistered = () => {
    const sortedDevices = [...devices];
    sortedDevices.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setDevices(sortedDevices);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSearchChange = (e) => {
    // Update the search criteria state when inputs change
    setSearchCriteria({
      ...searchCriteria,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSearchUsingQr = (e) => {
  //   // Update the search criteria state when inputs change
  //   setSearchCriteria({
  //     ...searchCriteria,
  //     [e.target.name]: 'E155M C69 699',
  //   });
  // };

  const handlePageDataUpdate = (data) => {
    setDevices(data); // Update the data for the current page
  };

  const openQRCodeScanner = () => {
    setShowModal(true);
  };

  const closeQRCodeScanner = () => {
    setQRCodeScannerOpen(false);
  };

  const handleQRCodeScan = (data) => {
    // Handle the scanned QR code data here
    console.log('Scanned QR Code Data:', data);
    // You can perform any necessary actions with the data
  };

  const exportCSVData = async () => {
    try {
        // Transform the devices data into an array of arrays
        const xlsxData = devices.map((device) => ([
            device.brand,
            device.model,
            device.serial_no,
            device.counter_black,
            device.counter_color,
            device.total_counter,
            device.device_options.blue === 1 ? 'Yes' : '',
            device.device_options.cabinet === 1 ? 'Yes' : '',
            device.device_options.finisher === 1 ? 'Yes' : '',
            device.device_options.notice_required === 1 ? device.device_options.notice_text : '',
            device.device_options['rt-lct'] === 1 ? 'Yes' : '',
            device.device_options['no-df'] === 1 ? 'Yes' : '',
            // Add other fields as needed
        ]));

        // Add headers as the first row
        const headers = [
            "Brand",
            "Model",
            "Serial",
            "Counter Black",
            "Counter Color",
            "Total Counter",
            "Blue",
            "Cabinet",
            "Finisher",
            "Notice Required",
            "Rt Lct",
            "No Df",
            // Add other headers as needed
        ];

        try {
          const response = await axiosInstance.post(
              `${window.REACT_APP_API_ENDPOINT}/api/device_export_pdf`,
              { 
                header: headers,
                data: xlsxData,
                type: 'device_list'
              
              }
          );
          
          const url = response.data

          // Create a link element and trigger a download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'datatable_export_' + new Date().toISOString().split('T')[0] + '.xlsx'); // Set the filename
          // document.body.appendChild(link);
          link.click();

          // Cleanup
          link.parentNode.removeChild(link);
          
      } catch (error) {
          console.error('Error:', error); // Handle any errors
      }
    } catch (error) {
        console.error('Failed to export CSV data:', error);
    }
};

const loadExcelFile = async (filePath) => {
    try {
        const response = await fetch(filePath);
        if (!response.ok) {
            throw new Error('Failed to fetch the file');
        }
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);

        // Read the Excel file with preserving styles
        const workbook = XLSX.read(data, { type: 'array', cellStyles: true });
        return workbook;
    } catch (error) {
        throw error;
    }
};

// Utility function to convert binary string to array buffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
};
  

  if (!devices) {
    return <p>Loading...</p>;
  }

  return (
    <AdminSidebar>
      {showLoading&&<div style={{ background: '#fff', width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, zIndex: 999999,display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      <div className="px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="sm:flex flex-col sm:items-start">
          <div className="w-full flex justify-between py-4 px-4 bg-white rounded-lg">
            <h3 className="font-bold text-2xl">Hello {user.name}</h3>
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-lg">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block lg:min-w-full max-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="w-full flex flex-col lg:flex-row justify-between items-center py-3 px-4 bg-white rounded-lg">
                <h4 className="font-semibold text-2xl mb-2 lg:mb-0">Registered Devices</h4>
                <div className="flex flex-col lg:flex-row items-center">
                  <button onClick={exportCSVData} className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold mr-0 lg:mr-4 mb-2 lg:mb-0">
                    Export Device Data as CSV
                  </button>
                  <button onClick={openQRCodeScanner} className="bg-bluePrimary text-white rounded-md px-4 py-3 font-bold lg:mr-4 mb-2 lg:mb-0">
                    Search QR Code
                  </button>
                  <a href="/devices/autoform" className="border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold lg:mr-4 mb-2 lg:mb-0">
                    Register A +
                  </a>
                  <a href="/devices/form" className="border border-bluePrimary text-bluePrimary rounded-md px-4 py-3 font-bold">
                    Register Device +
                  </a> 
                  <a className="pt-0 pr-0 pb-0 pl-3 text-left text-sm font-semibold">
                    {/* <span className='text-black flex'><MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('brand')}/></span> */}
                    <input
                      type="text"
                      name="brand"
                      onChange={handleSearchChange}
                      onKeyDown={searchAllDevices}
                      placeholder="Search"
                      className="border border-bluePrimary rounded-md py-3 text-sm mr-2"
                    />
                  </a>
                </div>
              </div>
              <div className='overflow-x-auto'>
                <table className="lg:min-w-full divide-y divide-gray-300">
                  <thead className="bg-black">
                    <tr>
                      <th scope="col" className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold">
                        <span className='text-white flex' onClick={sortByBrand}>Brand<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('brand')}/></span>
                        {showSearch.brand && <input
                          type="text"
                          name="brand"
                          value={searchCriteria.brand}
                          onChange={handleSearchChange}
                          onKeyDown={searchDevices}
                          placeholder="Brand"
                          className="rounded-md px-2 py-1 text-sm mr-2"
                        />}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                        <span className='text-white flex' onClick={() => filterByModel()}>Model<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('model')}/></span>
                        {showSearch.model && <input
                            type="text"
                            name="model"
                            value={searchCriteria.model}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Model"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                        <span className='text-white flex' onClick={() => filterBySerialNumber()}>Serial<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('serial_no')}/></span>
                        {showSearch.serial_no && <input
                            type="text"
                            name="serial_no"
                            value={searchCriteria.serial_no}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Serial"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        <span className='text-white flex' onClick={() => sortByCounterBlack()}>Counter Black<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('counter_black')}/></span>
                        {showSearch.counter_black && <input
                            type="text"
                            name="counter_black"
                            value={searchCriteria.counter_black}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Counter black"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        <span className='text-white flex' onClick={() => sortByCounterColor()}>Counter Color<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('counter_color')}/></span>
                        {showSearch.counter_color && <input
                            type="text"
                            name="counter_color"
                            value={searchCriteria.counter_color}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Counter color"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        <span className='text-white flex' onClick={() => sortByTotalColor()}>Total Color<MagnifyingGlassIcon className='w-4' onClick={() => toggleSearch('total_color')}/></span>
                        {showSearch.total_counter && <input
                            type="text"
                            name="total_color"
                            value={searchCriteria.total_counter}
                            onChange={handleSearchChange}
                            onKeyDown={searchDevices}
                            placeholder="Total color"
                            className="rounded-md px-2 py-1 text-sm mr-2"
                          />}
                      </th>
                      <th onClick={sortByDateRegistered} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        Date Registered
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        Options
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {devices.map((device) => (
                      <tr key={device.id}>
                        <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center pl-4">
                            <div className="">
                              <div className="font-medium text-gray-900">{device.brand}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                          <div className="mt-1 text-black">{device.model}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                          {device.serial_no}
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.counter_black}</td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.counter_color}</td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{device.total_counter}</td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-black">{new Date(device.created_at).toLocaleString()}</td>
                        {device.device_options &&<td className="whitespace-nowrap px-3 py-5 text-sm text-black">
                          {device.device_options.blue === 1 ? 'Blue | ' : ''}
                          {device.device_options.cabinet === 1 ? 'Cabinet | ' : ''}
                          {device.device_options.finisher === 1 ? 'Finisher | ' : ''}
                          {device.device_options.notice_required === 1 ? 'Notice_required | ' : ''}
                          {device.device_options['rt-lct'] === 1 ? 'rt-lct | ' : ''}
                          {device.device_options['no-df'] === 1 ? 'no-df | ' : ''}
                        </td>}
                        <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center pl-4">
                            <a href={"/devices/"+device.id+"/details"} className="text-indigo-600 hover:text-indigo-900 mr-2">
                              <QrCodeIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </a>
                            <a href={"/devices/form/"+device.id+"/edit"} className="text-indigo-600 hover:text-indigo-900 mr-2">
                              <PencilSquareIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </a>
                            <button onClick={() => openDeleteModal(device.id)}>
                              <TrashIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            </button>
                            {showDeleteModal && (
                              <div className="fixed z-10 inset-0 overflow-y-auto">
                                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                  </div>

                                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                      <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                          <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Device</h3>
                                          <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                              Are you sure you want to delete this device?
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                      <button
                                        onClick={() => handleDelete(deviceIdToDelete)}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                        Delete
                                      </button>
                                      <button
                                        onClick={closeDeleteModal}
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

{device.device_options&&
                            <div>
                            {device.device_options.blue === 0 && (
                              <div className="ml-2 flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8">
                                <input
                                  onChange={() => handleRadioChange(device.device_options.id)}
                                  id="bordered-radio-1"
                                  type="radio"
                                  value=""
                                  name="bordered-radio"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-textBlue focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-textBlue focus:ring-2 dark:bg-textBlue dark:border-textBlue"
                                />
                                <label
                                  htmlFor="bordered-radio-1"
                                  className="w-full py-4 ml-2 text-sm font-medium text-gray dark:text-gray"
                                >
                                  Blue Stock
                                </label>
                              </div>
                            )}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination apiEndpoint={window.REACT_APP_API_ENDPOINT + '/api/devices'} onDataUpdate={handlePageDataUpdate} />
              
            
              {showModal && <QReaderDeviceList onClose={()=>setShowModal(false)}/>}
              {/* {isQRCodeScannerOpen &&<QrReader
                delay={300}
                onScan={handleQRCodeScan}
                closeModal={closeQRCodeScanner}
                style={{ width: '100%' }}
              />} */}
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
}
